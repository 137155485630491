import { Theme } from "@mui/material";
import { createTheme } from "@mui/material";

const breakpoints = {
  values: {
    xs: 500,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1560,
    xxl: 1920,
  },
};

export const theme: Theme = createTheme({
  typography: {
    fontFamily: "Halcom",
  },
  breakpoints,
});
