import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./layouts/Layout";

const Home = lazy(() => import("./pages/Home" /* webpackChunkName: "home" */));

const Browse = lazy(
  () => import("./pages/Browse" /* webpackChunkName: "home" */)
);
const DetailPage = lazy(
  () => import("./pages/DetailPage" /* webpackChunkName: "home" */)
);

const Page404 = lazy(
  () => import("./pages/Page404" /* webpackChunkName: "home" */)
);

const Edition = lazy(
  () => import("./pages/Edition" /* webpackChunkName: "home" */)
);

const Routers = ({ children }: { children?: React.ReactNode }) => (
  <Router>
    {children}
    <Switch>
      <Route exact path="/">
        <Layout>
          <Home />
        </Layout>
      </Route>
      <Route exact path="/browse">
        <Layout>
          <Browse />
        </Layout>
      </Route>
      <Route exact path="/nft/*">
        <Layout>
          <DetailPage />
        </Layout>
      </Route>
      {/* <Route exact path="/edition">
        <Layout>
          <Edition />
        </Layout>
      </Route> */}
      <Route>
        <Page404 />
      </Route>
    </Switch>
  </Router>
);
export default Routers;
