import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { compressedImageBaseUrl } from "../constants";

const generateMerkleTree = (addresses: string[]) => {
  // Hash addresses
  const leaves = addresses.map((addr) => keccak256(addr));
  // Create Merkle Tree
  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
  return tree;
};

const generateMerkleRoot = (addresses: string[]) => {
  // Create Merkle Tree
  const tree = generateMerkleTree(addresses);
  // Get the Merkle Root
  const root = tree.getRoot();

  return root;
};

export const generateHexMerkleRoot = (addresses: string[]) => {
  // Hash addresses
  const root = generateMerkleRoot(addresses);
  // Convert to hex
  const hexRoot: `0x${string}` = `0x${root.toString("hex")}`;
  return hexRoot;
};

export const generateMerkleProof = (
  addresses: string[],
  address: string
): string[] => {
  // Create Merkle Tree
  const tree = generateMerkleTree(addresses);
  // Get the Merkle Proof
  const proof = tree.getHexProof(keccak256(address));
  return proof;
};

export const trimAddress = (address: string) => {
  const length = address.length;
  return `${address.slice(0, 4)}...${address.slice(length - 6, length)}`;
};

export function createArtLink(delimiter = "-", ...args: string[]) {
  if (!args.length) return "";
  return args
    .join(" ")
    .split(" ")
    .filter((val) => val.trim())
    .join(delimiter)
    .replaceAll(/[#?/%]+/g, "");
}

export const areEqual = (str1: any, str2: any) => {
  return String(str1).toLowerCase() === String(str2).toLowerCase();
};

export const getImageUrl = (url: string, compress: boolean, width: number) => {
  if (url.includes("/")) return url; // this means that the artwork is not processed yet
  if (compress) {
    return `${compressedImageBaseUrl}/${url}?w=${Math.floor(width / 2)}q=50`;
  }
  return `https://storage.googleapis.com/ninfa-assets/nfts/original/${url}`;
};
