import React from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, Theme, Typography } from "@mui/material";
import { ReactComponent as PendingIcon } from "../../assets/pending.svg";
import { base, mainnet } from "viem/chains";

type Props = {
  open: boolean;
  action: (id: number) => void;
  pending?: boolean;
  showEth?: boolean;
  showBase?: boolean;
};

const SwitchNetworkPopup = (props: Props) => {
  const classes = useStyles();
  const {
    open,
    action,
    pending = false,
    showEth = false,
    showBase = false,
  } = props;
  return (
    <Dialog open={open} className={classes.dialog}>
      <div className={classes.root}>
        <Typography color="#000" fontSize={22} fontWeight={600}>
          switch network
        </Typography>
        <Typography color="#000" fontSize={10} fontWeight={200}>
          It seems you are not connected to the right network, please switch to
          Ethereum mainnet to continue.
        </Typography>

        <div className={classes.buttonContainer}>
          {showEth ? (
            <button
              onClick={() => action(mainnet.id)}
              className={classes.button}
              disabled={pending}
            >
              {pending ? (
                <PendingIcon className={classes.pendingIcon} />
              ) : (
                <Typography fontSize={14} fontWeight={300} color="#fff">
                  ethereum
                </Typography>
              )}
            </button>
          ) : null}
          {/* {showBase ? (
            <button
              onClick={() => action(base.id)}
              className={classes.button}
              disabled={pending}
            >
              {pending ? (
                <PendingIcon className={classes.pendingIcon} />
              ) : (
                <Typography color="#fff">base</Typography>
              )}
            </button>
          ) : (
            false
          )} */}
        </div>
      </div>
    </Dialog>
  );
};

export default SwitchNetworkPopup;

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: 18,
      "&::-webkit-scrollbar": {
        display: "none",
      },
      backgroundColor: "white",
      MsOverflowStyle: "none" /* IE and Edge */,
      scrollbarWidth: "none" /* Firefox */,
      position: "relative",
      paddingTop: 30,
      maxHeight: "100% !important",
      // [theme.breakpoints.down("xs")]: {
      //   width: "100%",
      // },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
      },
    },
  },
  root: {
    padding: "10px 60px 30px 60px",
    width: 500,
    display: "flex",
    flexDirection: "column",
    gap: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 20,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "#000",
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 20,
  },
  button: {
    border: "none",
    backgroundColor: "#000",
    minWidth: 100,
    padding: "2px 6px",
    borderRadius: 5,

    transition: "all 0.3s ease",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    position: "relative",
  },
  pendingIcon: {
    width: 24,
    animation: "$rotateAnimation 2s linear infinite",
  },
  "@keyframes rotateAnimation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));
