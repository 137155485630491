export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0xebbb27a624ef47bf3e5aa00e528b320c8d87d08a";

export const artistId = 3057;
export const artistAddress = "0xD809a687e957761872B440909348FA6547CafaBf";

export const projectName = "The Midst of Reality and Imagination";
export const projectLink = "https://samanthacavet.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1721664000000;

export const artistName = "Samantha Cavet";
export const bio = [
  "Samantha Cavet, a photographer born in Caracas, Venezuela, embarked on a transformative journey in 2015 when she relocated to Madrid, Spain, driven by the pursuit of superior educational and personal opportunities. Raised with a passion for both travel and a deep appreciation for the connection between landscapes and the art of photography, Samantha’s artistic journey took a more earnest turn in 2020. Her work centers around the profound exploration of human emotions, delving into realms of nostalgia, melancholy, and tranquility. With a distinct personal touch, she artfully weaves dreamy pictorial landscapes that pay homage to the brushstrokes of Impressionist painters. Samantha attributes the diverse influence of paintings, music, films, and poetry to her creative process. Constantly pushing the boundaries, she continues to experiment with colors, concepts, and image editing, elevating her craft to create scenes that captivate with their visual allure, deep satisfaction, and an almost ethereal quality.",
  "Samantha's work was exhibited in New York USA, Paris France, Lisbon Portugal, and Madrid Spain",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@samanthacavet";
export const artistTwitter = "https://twitter.com/@samanthacavet";
export const artistInsta = "https://www.instagram.com/samanthacavet/";

export const interviewPath = "/interview/A-conversation-with-Samantha-Cavet-4";
export const articlePath =
  "/article/Impressionist-Inspirations:-A-short-study-of-Monet-Cavet,-Hokusai-and-the-Ever-Changing-Landscape-5";
export const standard = "ERC-721";
export const mintedOn = "Transient Labs";
export const totalPieces = "8";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
