import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import logo from "../assets/ninfa-logo.png";
import moment from "moment";
import { artistName } from "../constants";

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.poweredContainer}>
          <Typography fontSize={14} fontWeight={100}>
            project powered by NINFA
          </Typography>
          <img src={logo} alt="NINFA" className={classes.ninfaLogo} />
        </div>
        <Typography fontSize={14} fontWeight={100} textAlign={"center"}>
          © {moment().format("YYYY")} {artistName.toUpperCase()} - ALL RIGHTS
          RESERVED
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={100}
          className={classes.invisibleText}
        >
          project powered by NINFA
        </Typography>
      </div>
    </div>
  );
};

export default Footer;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#000",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
    height: "100%",
    margin: "auto",
    padding: "80px 0",
    gap: 50,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      gap: 20,
      padding: "50px 0",
    },
  },
  ninfaLogo: {
    width: 10,
    aspectRatio: "1/1",
  },
  poweredContainer: {
    display: "flex",
    alignItems: "center",
  },
  invisibleText: {
    opacity: 0,
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
